import React from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Card,
  CardMedia,
  CardContent,
  Typography,
} from "@mui/material";
import blogs from "../AllBlogs/blog";
import Footer from "../../footer";

const BlogDetail = () => {
  const { id } = useParams();
  const blog = blogs.find((blog) => blog.id === id);

  if (!blog) {
    return <Typography>Blog not found</Typography>;
  }

  return (
    <>
      <Container sx={{ padding: 2 }}>
        <Card>
          {blog.image && (
            <CardMedia
              component="img"
              height="500"
              image={blog.image}
              alt={blog.title}
              sx={{ flexShrink: 0 }}
            />
          )}
          <CardContent>
            <Typography m={2} mb={4} variant="h3" gutterBottom>
              {blog.title}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              dangerouslySetInnerHTML={{ __html: blog.content }}
            />
          </CardContent>
        </Card>
      </Container>
      <Footer />
    </>
  );
};

export default BlogDetail;
