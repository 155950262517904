import React, { Suspense } from "react";

import service1 from "../../assets/images/services-page/service1.png";
import service2 from "../../assets/images/services-page/service2.jpg";
import service3 from "../../assets/images/services-page/service3.jpg";
import service4 from "../../assets/images/services-page/service4.jpg";
import service5 from "../../assets/images/services-page/service5.jpg";
import service6 from "../../assets/images/services-page/service6.jpg";
import service7 from "../../assets/images/services-page/service7.png";
import service8 from "../../assets/images/services-page/service8.jpg";
import service9 from "../../assets/images/services-page/service9.jpg";

const AboutUs = React.lazy(() => import("../../Components/Aboutus"));
const Founder = React.lazy(() => import("../../Components/Service"));
const Footer = React.lazy(() => import("../../footer"));

const Expert = [
  {
    image: service1,
    description:
      "<h2>Year-end Accounts Outsourcing</h2> <p> Processing year-end accounting outsourcing for accountants. Our expert team of accountants has will process the accounting information in IRIS, CCH, RAP, TaxCalc, also able to work in your software using RDP secured connection. </p> <p>– Full sets of accounts and tax</p> <p>– Preparation of working file (All format)</p> <p>– Trial Balance</p> <p>– Draft accounts for review</p> <p>– Posting accounts in the software</p> <p>– Filing of accounts</p>",
  },
  {
    pos: "left",
    image: service2,
    name: "",
    description:
      "<h2>Bookkeeping & VAT Outsourcing</h2><p> We make your job easy by taking care of all your bookkeeping tasks. By outsourcing bookkeeping services and faster turnaround times, you can focus more on your practice area and make it more profitable. </p> <p>– Basic Bookkeeping & VAT work</p> <p>– Bank account reconciliation</p> <p>– Management accounts</p> <p>– VAT Returns</p> ",
  },
  {
    image: service3,
    name: "",
    description:
      "<h2>Corporation Tax Outsourcing</h2><p> We offer tax processing services to accounting firms across the UK and Ireland. Many accountants use outsourcing services to prepare and file CT600 precisely and cost-effectively. </p> <p>– Tax computationx</p> <p>– CT600</p> <p>– Online filling of CT600 with HMRC</p>",
  },
  {
    pos: "left",
    image: service4,
    description:
      "<h2 > Self-Assessment Tax Return Outsourcing </h2> <p> At FLYFIN for outsourcing self-assessment tax returns, we make sure accountants get full support during the busy January tax season. Every year out tax return team prepare hundreds of self-assessment tax return. </p> <p>– Preparation of tax return</p> <p>– Individual tax summary report</p> <p>– Online filing of tax return with HMRC before the deadline</p> <p>– Tax computation statement</p>",
  },
  {
    image: service5,
    description:
      "<h2>Payroll Outsourcing</h2> <p> We provide very efficient payroll outsourcing to accountants in the UK. We have formed extraordinary technology infrastructure to deliver accurate payroll outsourcing services. </p> <p>– Processing payroll</p> <p>– New company set-up and RTI</p> <p>– Producing payslips (Weekly and monthly)</p> <p>– Processing of P60 and P45</p> <p>– Year-end report</p> <p>– Pension Enrollment</p>",
  },
  {
    pos: "left",
    image: service6,
    description:
      "<h2>Audit services you can trust</h2> <p> In times of change and uncertainty, confidence becomes more important than ever. Our award-winning audit service delivers the rigour and quality you need to gain the assurance and confidence that’s so essential for your business. Perfect blend of people and technology For some time now we have been investing in our AI and data analytics capabilities, which has helped drive innovation in our audit methodology and processes. Our commitment to technology is a fundamental part of our success and the way we work. We continue to invest in our human capabilities knowing that by bringing together the best people with cutting-edge technology, we can help our clients create long-term value and provide high-quality assurance. </p> <p>– Our services</p> <p>– Audit</p> <p>– Financial reporting</p> <p>– Nonprofit reporting</p> <p>- Royalty audits</p>",
  },
  {
    image: service7,
    description:
      "<h2>Virtual CFO</h2> <p> With the introduction of the concept of Virtual CFO small businesses now receive support that they could not have afford previously. Virtual CFO has crafted a way for small businesses, following which they can get access to an experienced financial professional at an affordable cost. Though the services of CFO differ from firm to firm, However, following services are common and often rendered to every firm who hire a Virtual CFO: </p> <p> – It takes control of all the duties of a traditional CFO, but solely on a part-time basis </p> <p> – Keeps an eye on the financial health of the business usually by adopting cloud technology </p> <p> – Offer financial guidance and insight to the business on the matters related to finance. </p> <p> – Give companies back-office functions which include managing account ledgers, depending on the client and their needs. </p> <p> Often companies who cannot afford to have in-house CFO prefers to go with Virtual CFO. Organizations deal with many challenges on a daily basis in terms of financial aspects, growth, accounting as well as management. To curb those challenges, a need arises to appoint a Virtual CFO who can primarily be responsible for managing activities like financial reporting, record keeping and financial risks of the company. Presence of Virtual CFO helps in meeting those challenges effectively by giving financial and professional aid, analysis and support to the management </p>",
  },
  {
    pos: "left",
    image: service8,
    name: "",
    description:
      "<h2>MIS Reporting</h2>" +
      "<p>MIS (Management Information System) Reporting involves collecting, analyzing, and presenting data to support management decision-making. Our MIS reporting services offer detailed insights into business performance and trends. By utilizing specialized software tools, we aggregate data from various sources like databases, spreadsheets, and business systems. This allows us to create comprehensive reports, dashboards, and visualizations that provide actionable insights. Key benefits include:</p>" +
      "<ul>" +
      "<li>Improved operational efficiency</li>" +
      "<li>Enhanced strategic planning</li>" +
      "<li>Better risk management</li>" +
      "</ul>" +
      "<p>We support various functions, including finance, marketing, and operations, ensuring businesses can make data-driven decisions and identify opportunities for growth.</p>",
  },
  {
    image: service9,
    name: "",
    description:
      "<h2>Financial Modeling</h2>" +
      "<p>Financial Modeling involves creating detailed representations of a business's financial performance to aid in decision-making. Our financial models convert complex business opportunities into meaningful data, assisting management with strategic planning and forecasting. We emphasize:</p>" +
      "<ul>" +
      "<li>Accuracy: Strive for 99%+ accuracy with multiple checks and error-detecting mechanisms</li>" +
      "<li>Flexibility: Build models with the capability to handle multiple business scenarios and fluctuations</li>" +
      "<li>Clarity: Focus on creating logical, easy-to-understand models</li>" +
      "<li>Best Practices: Adopt global best practices in designing intuitive interfaces</li>" +
      "</ul>" +
      "<p>Our expertise spans various sectors, including corporate, investment banking, and private equity. By integrating advanced analytics and global best practices, our financial models help businesses achieve their financial goals and manage risks effectively.</p>",
  },
];

function Expertise() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AboutUs pageTitle="Our Expertise" />
      {Expert.map((member, index) => (
        <Founder
          key={index}
          image={member.image}
          name={member.name}
          title={member.title}
          description={member.description}
          imagePosition={member.pos}
        />
      ))}
      <Footer />
    </Suspense>
  );
}

export default Expertise;
