import React, { Suspense } from "react";

const AboutUs = React.lazy(() => import("../../Components/Aboutus"));
const Blog = React.lazy(() => import("../../Components/AllBlogs"));
const Footer = React.lazy(() => import("../../footer"));

function Expertise() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AboutUs pageTitle="Our Blogs" />
      <Blog />
      <Footer />
    </Suspense>
  );
}

export default Expertise;
