import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Hidden,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import favicon from "../assets/images/logo/only-logo.png";
import textlogo from "../assets/images/logo/text-logo.png";
import "./navbar.css";

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const changeBackground = () => {
    setNavbar(window.scrollY >= 66);
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
    return () => window.removeEventListener("scroll", changeBackground);
  }, []);

  const menuItems = [
    { text: "Home", path: "/" },
    { text: "About Us", path: "/about-us" },
    { text: "Professionals", path: "/professionals" },
    { text: "Expertise", path: "/our-expertise" },
    { text: "Blogs", path: "/blogs" },
    { text: "Contact Us", path: "/contact-us" },
  ];

  return (
    <React.Fragment>
      <AppBar
        elevation={0}
        position="sticky"
        sx={{
          backgroundColor: navbar ? "#fff" : "transparent",
          color: navbar ? "#000" : "#fff",
          backdropFilter: navbar ? "blur(10px)" : "none",
          padding: "10px",
        }}
      >
        <Toolbar>
          <Typography variant="h2" style={{ flexGrow: 1 }}>
            <Link to="/">
              <img
                src={favicon}
                alt="Logo"
                style={{ height: "35px", margin: "0px" }}
              />
              <img
                src={textlogo}
                alt="Logo-Name"
                style={{ height: "35px", marginTop: "10px" }}
              />
            </Link>
          </Typography>
          <Hidden mdUp>
            <IconButton
              color="inherit"
              onClick={handleDrawerToggle}
              sx={{ color: "#000" }}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden smDown>
            <div className="nav-links">
              {menuItems.map((item, index) => (
                <Button
                  key={index}
                  component={Link}
                  to={item.path}
                  style={{
                    color:
                      location.pathname === item.path
                        ? "#00008b"
                        : navbar
                        ? "#000"
                        : "#000",
                    fontWeight:
                      location.pathname === item.path
                        ? "800"
                        : navbar
                        ? "400"
                        : "400",
                    marginRight: "10px",
                    borderBottom:
                      location.pathname === item.path
                        ? "2px solid #000"
                        : "none",
                  }}
                >
                  {item.text}
                </Button>
              ))}
            </div>
            <IconButton
              href="https://wa.me/+919311357594"
              color="inherit"
              sx={{ color: "green" }}
            >
              <WhatsAppIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
        <div style={{ width: 250 }}>
          <List>
            {menuItems.map((item, index) => (
              <ListItem
                key={index}
                button
                component={Link}
                to={item.path}
                onClick={handleDrawerToggle}
                style={{
                  borderBottom:
                    location.pathname === item.path ? "2px solid #000" : "none",
                }}
              >
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
            <IconButton
              href="https://wa.me/+919311357594"
              color="inherit"
              sx={{ color: "green", fontSize: "100px" }}
            >
              <WhatsAppIcon />
            </IconButton>
          </List>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default Navbar;
